var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"pa-0 pb-2",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-12",attrs:{"cols":"auto"}},[_c('h1',[_vm._v("Orders")])]),_c('v-col',{staticClass:"pl-12"},[_c('v-text-field',{attrs:{"label":"Search Orders","outlined":"","dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"align":"center","justify":"end","no-gutters":""}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.orders,"disable-pagination":true,"hide-default-footer":true,"no-data-text":"There are currently no orders"},scopedSlots:_vm._u([{key:"item.id",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
            name: 'module-lhl-orders-individual',
            params: { orderId: item.id },
          }}},[_vm._v("#"+_vm._s(item.identifier)+" "+_vm._s(item.customer.full_name))])]}},{key:"item.status",fn:function({ item }){return [(item.status === 'processing')?_c('v-chip',{attrs:{"label":"","color":"blue","text-color":"white"}},[_vm._v("Processing")]):(item.status === 'pending-payment')?_c('v-chip',{attrs:{"label":"","color":"warning"}},[_vm._v("Pending Payment")]):(item.status === 'cancelled')?_c('v-chip',{attrs:{"label":""}},[_vm._v("Cancelled")]):(item.status === 'complete')?_c('v-chip',{attrs:{"label":"","color":"success"}},[_vm._v("Complete")]):_vm._e()]}},{key:"item.total",fn:function({ item }){return [_vm._v(" £"+_vm._s(item.total)+" ")]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","depressed":"","color":"green lighten-4 green--text","to":{
            name: 'module-lhl-orders-individual',
            params: { orderId: item.id },
          }}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-eye")]),_vm._v(" View ")],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"x-small":"","depressed":"","color":"red lighten-4 red--text"}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-archive")])],1)]}}],null,true)},[_c('span',[_vm._v("Archive")])])]}}])})],1),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"400"},model:{value:(_vm.deleteOrder.dialog),callback:function ($$v) {_vm.$set(_vm.deleteOrder, "dialog", $$v)},expression:"deleteOrder.dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Delete Order")]),_c('v-card-text',[_vm._v("Are you sure you wish to delete "+_vm._s(_vm.deleteOrder.order.id)+"?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","depressed":""}},[_vm._v("No, cancel")]),_c('v-btn',{attrs:{"color":"success","depressed":"","loading":_vm.deleteOrder.loading}},[_vm._v("Yes")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }